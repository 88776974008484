/****************admin******************/
.admin {
    display: flex;
}

    /****************sidebar******************/
    .admin .pro-sidebar-content ul .active li {
        background-color: #e26300 !important;
    }

    /****************main container******************/
    .admin .mainContainer {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
        height: 100vh;
    }

.pro-sidebar-footer .btn-kuzel {
    padding: 0px;
    font-size: 15px;
}

/****************responzivita******************/
@media (max-width: 835px) {
    .admin {
        display: block;
    }

        .admin .pro-sidebar {
            width: 100% !important;
            min-width: 100% !important;
        }

            .admin .pro-sidebar.toggled {
                height: 55px;
            }

            .admin .pro-sidebar div div {
                overflow: hidden;
            }

        .admin .head-div {
            padding: 15px 30px !important;
        }

            .admin .head-div.toggled {
                -webkit-box-pack: justify !important;
                justify-content: space-between !important;
            }

                .admin .head-div.toggled .head-text {
                    display: block !important;
                }

        .admin .pro-sidebar.toggled .sidebar-content {
            display: none;
        }

        .admin .pro-sidebar.toggled .pro-sidebar-footer {
            display: none;
        }

        .admin .mainContainer {
            position: absolute;
            top: 55px;
            left: 0px;
            height: calc(100vh - 55px);
        }
}

/****************detail******************/
.admin .adminDetail {
    cursor: initial;
    background-color: rgb(51, 51, 51);
}

    .admin .adminDetail .form-label, .admin .adminDetail .form-check-label {
        color: white;
    }

    .admin .adminDetail .form-label {
        margin-top: 10px;
    }

    .admin .quill .ql-snow {
        background-color: white;
    }

/****************button******************/
.admin .btn-kuzel.secondary:before {
    background-color: rgb(51, 51, 51) !important;
}

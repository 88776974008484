.App {
    text-align: center;
}

    .App .bg-primary {
        background-color: #e26300 !important;
    }

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

svg {
    cursor: pointer;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotate0deg {
    transform: rotate(0deg);
    transition-duration: 0.4s;
}

.rotate90deg {
    transform: rotate(90deg);
    transition-duration: 0.4s;
}

.rotate180deg {
    transform: rotate(180deg);
    transition-duration: 0.4s;
}

.opacityHoverNone:hover {
    opacity: 1 !important;
    cursor: pointer;
}

.opacityHover:hover {
    opacity: 0.8 !important;
    cursor: pointer;
}

.hoverUnsetBackground:hover {
    background-color: unset !important;
}

.bg-kuzel {
    background-color: #e26300 !important;
    color: #fff !important;
}

    .bg-kuzel:hover {
        color: #6e6e6e;
    }

.color-kuzel {
    color: #e26300 !important;
}

.border-kuzel {
    border-color: #e26300 !important;
}

.badge-role {
    margin: 1px;
}

/************login page************/
@import url(https://fonts.googleapis.com/css?family=Exo:100,200,400);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:700,400,300);

.body {
    margin: 0;
    padding: 0;
    background: #fff;
    cursor: url('../kurzorKuzel.svg'), auto;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
}

.loginBody {
    position: absolute;
    top: 0;
    left: 0;
    right: -40px;
    bottom: -40px;
    width: 100%;
    height: 100%;
    background-image: url('../loginkuzel.jpg');
    background-size: cover;
    -webkit-filter: blur(5px);
    z-index: 0;
}

.grad {
    position: absolute;
    top: 0;
    left: 0;
    right: -40px;
    bottom: -40px;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 1.65))); /* Chrome,Safari4+ */
    z-index: 1;
}

ol > li::marker {
    font-weight: bold;
}

.login a {
    color: #e26300 !important;
}

.homePageContent {
    position: absolute;
    top: calc(47% - 35px);
    left: calc(45% - 230px);
    z-index: 2;
    display: flex;
}

    .homePageContent .motto {
        margin-right: 45px;
        text-align: center;
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

        .homePageContent .motto .mottoContent {
            text-align: center;
            width: auto;
            margin: auto;
            width: 250px;
        }

            .homePageContent .motto .mottoContent .mottoElement {
                color: #fff;
                font-family: 'Exo', sans-serif;
                font-size: 33px;
                font-weight: 200;
                text-align: left;
                width: 250px;
                margin-left: 0px;
                display: block;
            }

                .homePageContent .motto .mottoContent .mottoElement:nth-child(2) {
                    margin-left: 35px;
                }

                .homePageContent .motto .mottoContent .mottoElement:nth-child(3) {
                    margin-left: 70px;
                }

                .homePageContent .motto .mottoContent .mottoElement span {
                    color: #e26300 !important;
                }

    .homePageContent .login {
        height: 150px;
        z-index: 2;
        display: grid;
        align-content: center;
        margin-top: 3px;
    }

        .homePageContent .login input[type=text], .homePageContent .login input[type=password], .homePageContent .login input[type=email] {
            width: 265px;
            height: 40px;
            background: transparent;
            border: 1px solid rgba(255, 255, 255, 0.6);
            border-radius: 2px;
            color: lightcyan;
            font-family: 'Exo', sans-serif;
            font-size: 16px;
            font-weight: 400;
            padding: 4px;
            margin-bottom: 7px;
        }

            .homePageContent .login input[type=text]::placeholder, .homePageContent .login input[type=password]::placeholder, .homePageContent .login input[type=email]::placeholder {
                color: lightgrey !important;
            }

            .homePageContent .login input[type=text]:-moz-placeholder, .homePageContent .login input[type=password]:-moz-placeholder, .homePageContent .login input[type=email]:-moz-placeholder {
                color: lightgrey !important;
            }

            .homePageContent .login input[type=text]::-webkit-input-placeholder, .homePageContent .login input[type=password]::-webkit-input-placeholder, .homePageContent .login input[type=email]::-webkit-input-placeholder {
                color: lightgrey !important;
            }

        .homePageContent .login input[type=button], .homePageContent .login button[type=submit] {
            width: 265px;
            height: 36px;
            background: #e26300;
            border: 1px solid #e26300;
            cursor: pointer;
            border-radius: 2px;
            color: #fff;
            font-family: 'Exo', sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding: 2px;
        }

            .homePageContent .login input[type=button]:hover, .homePageContent .login button[type=submit]:hover {
                opacity: 0.8;
            }

            .homePageContent .login input[type=button]:active {
                opacity: 0.6;
            }

        .homePageContent .login input[type=text]:focus {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.9);
        }

        .homePageContent .login input[type=password]:focus {
            outline: none;
            border: 1px solid rgba(255, 255, 255, 0.9);
        }

        .homePageContent .login input[type=button]:focus {
            outline: none;
        }

    .homePageContent .errorMessage {
        color: mistyrose;
        height: 22px;
    }

::-webkit-input-placeholder {
    color: gray !important;
}

::-moz-input-placeholder {
    color: gray !important;
}

.form-label {
    font-size: 0.9rem !important;
    margin-bottom: 2px !important;
}

@media (max-width: 835px) {
    .homePageContent {
        display: block;
        width: 100%;
        left: 0;
        top: 90px;
    }

        .homePageContent .motto {
            margin-bottom: 25px;
            width: 100%;
        }

        .homePageContent .login {
            display: inline-grid;
            max-width: 265px;
        }
}

/*************modal************/
@media (max-width: 835px) {
    .modal-content {
        font-size: 13px !important;
    }
}

.fullHeight .modal-dialog-scrollable .modal-content {
    height: 80%;
}

@media (max-width: 1835px) {
    .fullHeight .modal-dialog-scrollable .modal-content {
        height: 100%;
    }
}
/************table************/
table tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

table tbody tr:hover {
    background-color: #ee660c !important;
    color: #fff !important;
    opacity: 0.7 !important;
}

table thead tr th:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

table thead tr th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

table th {
    background-color: #e26300 !important;
    color: #fff !important;
    font-weight: 600;
}
/************checkbox************/

input[type=checkbox] {
    border-color: #e26300 !important;
    height: 18px;
    width: 18px;
}

    input[type=checkbox]:checked {
        background-color: #e26300 !important;
        border-color: #ee660c !important;
    }

    input[type=checkbox]:focus {
        box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25);
    }

.form-check .form-check-label {
    margin-left: 4px;
    margin-top: 2px;
}

/************switch************/
input [role=switch] {
    border-color: rgb(238, 102, 12, 0.4) !important;
}

    input[role=switch]:checked {
        background-color: #e26300;
        border-color: #e26300;
    }

    input[role=switch]:focus {
        box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25);
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='lightgray'/></svg>");
    }

.form-check-label {
    user-select: none;
}


/************select************/

select:focus {
    border-color: rgb(238, 102, 12, 0.4) !important;
    box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25) !important;
}

input.form-control:focus, .form-control:focus {
    border-color: rgb(238, 102, 12, 0.4) !important;
    box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25) !important;
}

@media (max-width: 860px) {
    .css-wsp0cs-MultiValueGeneric, .css-1p3m7a8-multiValue {
        max-width: 280px;
    }
}

/************navbar************/

.navbarMenu {
    position: fixed;
    top: 0px;
    left: 5px;
    padding-top: 12px;
    z-index: 402;
    cursor: pointer;
}

    .navbarMenu.right {
        right: 5px;
        left: unset;
        z-index: 401;
    }

    .navbarMenu.secondRow {
        top: 46px;
    }

    .navbarMenu:focus-visible {
        outline: none;
    }

    .navbarMenu svg.socialIcon {
        font-size: 29px;
        color: #6e6e6e;
        background-color: white;
        padding: 2px;
        margin: 0px 8px 0 8px;
        border-radius: 5px;
    }

    .navbarMenu svg.mainIcon {
        color: #6e6e6e;
        background-color: white;
        padding: 7px;
        margin: 0 3px 0 3px;
        border-radius: 20px;
        width: 27px;
        height: 27px;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.163), 0 0.6px 2px rgba(0, 0, 0, 0.1);
    }

        .navbarMenu svg.mainIcon:hover, .navbarMenu svg.socialIcon:hover {
            opacity: 0.8;
        }

    .navbarMenu .menuIcon {
        width: 18px;
        margin-right: 12px;
    }

    .navbarMenu .szh-menu {
        margin-left: -2px !important;
        margin-top: 2px !important;
    }

    .navbarMenu.right .szh-menu {
        margin-left: -8px !important;
    }

    .navbarMenu .szh-menu-button {
        box-sizing: border-box;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        padding: 0.42rem 0.75rem;
        line-height: 1.5;
        color: #fff;
        background-color: #e26300;
        border-radius: 0.25rem;
        border: 1px solid #ddd;
    }

    .navbarMenu .szh-menu__item {
        justify-content: center;
    }

    .navbarMenu .groupsButton {
        float: right;
        height: 40px;
        margin-left: 3px;
        min-width: 150px;
        user-select: none;
        background-color: white !important;
        color: #6e6e6e !important;
        border-radius: 20px !important;
        font-size: 17px !important;
        padding-left: 13px !important;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.163), 0 0.6px 2px rgba(0, 0, 0, 0.1);
    }

        .navbarMenu .groupsButton svg {
            margin-top: 5px;
            margin-left: 5px;
        }

    .navbarMenu .competitions {
        float: right;
        margin: 3px;
        height: 40px;
        padding-top: 7px;
        min-width: 135px;
        user-select: none;
        border-radius: 20px !important;
        font-size: 17px !important;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.163), 0 0.6px 2px rgba(0, 0, 0, 0.1);
    }

    .navbarMenu .notificationButton {
        height: 41px;
        width: 41px;
        background-color: transparent !important;
        color: #6e6e6e !important;
        padding: 0;
        margin: 0 6px 0 0;
        border: none;
        border-radius: 20px;
    }

    .navbarMenu .numberOfNotifications {
        position: absolute;
        top: 2px;
        left: 28px;
        background-color: #e26300;
        height: 21px;
        width: 21px;
        border-radius: 11px;
        color: #fff;
        font-size: 13px;
        user-select: none;
    }

.notificationsContent .numberOfNotifications {
    position: relative;
    height: 0;
}

    .notificationsContent .numberOfNotifications .number {
        position: absolute;
        top: -20px;
        left: 12px;
        display: block;
        background-color: #e26300;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        color: #fff;
        font-size: 13px;
        user-select: none;
        border: 2px solid #fff;
    }


.notificationsContent .notificationText b {
    color: #e26300 !important;
}

/************scrollbar************/
/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e26300;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

.offcanvas-body, .modal-body {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    padding-right: 10px !important;
}

/************pop up************/
.leaflet-popup {
    min-width: 345px;
}

.leaflet-popup-close-button {
    margin: 65px 0 0 0 !important;
    top: 5px !important;
    right: 7px !important;
    height: 28px !important;
}

.kuzel-popup-wrapper .leaflet-popup-close-button {
    color: #fff !important
}

    .kuzel-popup-wrapper .leaflet-popup-close-button:hover {
        background-color: #e26300 !important
    }

.request-popup .leaflet-popup-close-button {
    color: #6e6e6e !important;
}

.leaflet-popup-close-button:focus {
    transform: scale(1.1);
    background-color: #e26300 !important
}

.leaflet-popup-close-button span {
    font-size: 27px
}

.leaflet-popup-content {
    margin: 0 !important;
    padding: 13px 16px 13px 16px;
}

.leaflet-popup-content-wrapper {
    margin: 60px 0 0 0px !important;
    padding: 0 !important;
}

    .leaflet-popup-content-wrapper .leaflet-popup-content {
        width: unset !important;
        padding: 0;
    }

@media (max-width: 370px) {
    .leaflet-popup {
        min-width: calc( 100vw - 20px);
    }
}

/***************pagination**************/

.kuzel-pagination {
    --bs-pagination-hover-color: #e26300 !important;
    --bs-pagination-active-bg: #e26300 !important;
    --bs-pagination-active-border-color: #e26300 !important;
    --bs-pagination-color: #e26300 !important;
}

/***************kuzel popup**************/

.kuzel-popup-wrapper {
    margin-left: 10px;
}

    .kuzel-popup-wrapper .leaflet-popup-content {
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0;
    }

    .kuzel-popup-wrapper .leaflet-popup-content-wrapper {
        padding: 0 !important;
    }

.kuzel-popup {
    background: white;
}

.kuzel-popup-info {
    padding: 10px 0;
    min-height: 60px;
    background: #e26300;
    color: #f3f3f3;
    font-size: 15px;
    position: relative;
}

.kuzel-popup-overflow {
    position: absolute;
    background: #e26300;
    width: 22px;
    left: -20px;
    height: 100%;
    bottom: 0;
    top: 0;
    z-index: 2;
}

.kuzel-popup-overflow-effect {
    position: absolute;
    background: #8d4114;
    z-index: -1;
    height: 29px;
    transform: rotate(50deg);
    left: -15px;
    width: 31px;
    top: -11px;
}

/****************button**************/

.btn-kuzel {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: Eina01,sans-serif;
    font-size: 16px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 38px;
    outline: none;
    overflow: visible;
    padding: 4px 13px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
}

    .btn-kuzel:before,
    .btn-kuzel:after {
        border-radius: var(--bs-border-radius);
    }

    .btn-kuzel:before {
        background-color: rgba(249, 58, 19, .32);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -2;
    }

    .btn-kuzel:after {
        background-color: #e26300 !important;
        bottom: 2px;
        content: "";
        display: block;
        left: 2px;
        overflow: hidden;
        position: absolute;
        right: 2px;
        top: 2px;
        transition: all 100ms ease-out;
        z-index: -1;
    }

    .btn-kuzel:hover:not(:disabled):after {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition-timing-function: ease-in;
    }

    .btn-kuzel:active:not(:disabled) {
        color: #ccc;
    }

        .btn-kuzel:active:not(:disabled):after {
            background-color: #6c757d;
            bottom: 4px;
            left: 4px;
            right: 4px;
            top: 4px;
        }

    .btn-kuzel:disabled {
        cursor: default;
        opacity: .24;
    }

    .btn-kuzel.secondary:before {
        background-color: lightgray;
    }

    .btn-kuzel.secondary:after {
        background-color: rgb(51, 51, 51) !important;
    }

.btn-group {
    border: none;
}

.btn-outline-success {
    --bs-btn-color: #000 !important;
    --bs-btn-border-color: #dee2e6 !important;
    --bs-btn-active-bg: #ee660c !important;
    --bs-btn-active-border-color: #ee660c !important;
}

.btn-outline-secondary {
    --bs-btn-color: #000 !important;
    --bs-btn-border-color: #dee2e6 !important;
}

/****************multiselect**************/
.css-b62m3t-container .css-13cymwt-control {
    border-radius: 0.375rem;
}

.css-b62m3t-container .css-t3ipsp-control {
    border-color: rgb(238, 102, 12, 0.4) !important;
    box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25) !important;
}

/****************radiobutton**************/

.form-check-input:checked[type=radio] {
    background-color: rgb(238, 102, 12) !important;
    border-color: rgb(238, 102, 12) !important;
}

/****************file input**************/

.notSelectedImage {
    height: 222px;
    border: 1px solid rgba(0, 0, 0, 0.21);
    border-radius: 0.375rem;
    color: transparent;
}

    .notSelectedImage::before {
        content: 'Vybrať obrázok';
        width: 100%;
        height: 100%;
        display: inline-block;
        text-align: center;
        padding: 102px 0;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-size: 16px;
        font-weight: 400;
        color: black;
    }

    .notSelectedImage:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

.selectedImage img {
    margin: 0 auto;
    min-height: 90px;
    max-height: 195px !important;
    max-width: 100% !important;
}

@media (max-width: 576px) {
    .notSelectedImage {
        height: 261px;
    }

        .notSelectedImage::before {
            padding: 115px 0;
        }

    .selectedImage img {
        min-height: 100px;
        max-height: 235px !important;
    }
}

.hideCrop {
    visibility: hidden;
}

::-webkit-file-upload-button {
    display: none;
}

::file-selector-button {
    display: none;
}

/****************accordion**************/

.accordion-button {
    background-color: #e26300 !important;
    color: #fff !important;
}

    .accordion-button::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    }

    .accordion-button:focus {
        box-shadow: 0 0 0 0.25rem rgb(238, 102, 12, 0.25) !important;
    }

/****************zoom, search and geolocation controls**************/
.leaflet-control-container .leaflet-top.leaflet-left {
    margin-top: 100px;
}

    .leaflet-control-container .leaflet-top.leaflet-left div, .geolocationControl {
        -webkit-animation: fadeIn 1s;
        animation: fadeIn 0.4s;
    }


.leaflet-control-zoom a span {
    color: #6e6e6e !important;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/****************nav-tabs**************/
.nav-tabs {
    --bs-nav-tabs-border-width: none !important;
    border-bottom: solid #e26300 !important;
}

.nav-item {
    margin: auto 5px;
}

    .nav-item:first-of-type {
        margin: 0 5px 0 0;
    }

    .nav-item:last-of-type {
        margin: 0 0 0 5px;
    }

.nav-link {
    color: #000 !important;
}

.nav-tabs .nav-link {
    background-color: #eeedf0 !important;
}

    .nav-tabs .nav-link.active {
        color: white !important;
        background-color: #e26300 !important;
        transition: background-color 0.6s ease;
    }

.szh-menu__divider {
    background-color: #e26300;
}

/****************ConeImageGallery**************/
.carousel.carousel-slider .control-arrow {
    margin-bottom:32px;
}

/****************ConeImageDetail**************/
.coneEventDetailPanelIcons svg {
    color: #6c757d !important;
    vertical-align: -.8em !important;
    height: 25px;
}

    .coneEventDetailPanelIcons svg:focus {
        outline: none;
    }

.st1 {
    fill: #6c757d !important;
}

.kuzelLikeActive .st1 {
    fill: #e26300 !important;
}


/*zoom*/
.transform-component-module_wrapper__SPB86 {
    width: 100% !important;
}

.transform-component-module_content__FBWxo {
    width: 100% !important;
    justify-content: center;
}

/*******************stories******************/

.storiesContainer {
    position: fixed;
    top: 110px;
    right: 7px;
    height: calc(100% - 190px);
    z-index: 400;
    overflow-y: auto !important;
    color: red;
    font-size: 30px;
    padding-top: 8px;
    padding-bottom: 25px;
    mask-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,1) 2%, rgba(0,0,0,1) 89%, rgba(0,0,0,0.1) 100%);
    -webkit-mask-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 2%, rgba(0,0,0,1) 89%, rgba(0,0,0,0.1) 100%);
    user-select: none;
}


    .storiesContainer .addStory {
        background-color: snow;
        color: rgb(51, 51, 51);
        border: solid 1px rgb(51, 51, 51);
        border-radius: 26px;
        height: 50px;
        width: 50px;
    }

    .storiesContainer .stories .story {
        height: 52px;
        width: 52px;
    }


        .storiesContainer .stories .story img {
            height: 52px;
            width: 52px;
            border: solid 2px #e26300;
            border-radius: 26px;
            box-shadow: 0 3px 7px rgba(0, 0, 0, 0.163), 0 0.6px 2px rgba(0, 0, 0, 0.1);
        }

        .storiesContainer .stories .story.viewed img {
            border: solid 2px transparent;
        }


/******************spinner*******************/
@keyframes flickerAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    65% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animate-flicker {
    -webkit-animation: flickerAnimation 1.7s infinite;
    -moz-animation: flickerAnimation 1.7s infinite;
    -o-animation: flickerAnimation 1.7s infinite;
    animation: flickerAnimation 1.7s infinite;
}

.add-new-button {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
}


.no-bg-accordion > .accordion-button {
    background-color: unset !important;
    color: unset !important;
}

.cone-comment-text {
    background-color: rgb(226 99 0 / 36%);
    padding: 7px;
    border-radius: 10px;
}

.cone-comment-date {
    font-size: 12px;
}
